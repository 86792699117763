/*global History, Modernizr, FastClick, alert*/
/*jshint sub:true*/

window.main = window.main || {};

//General
var $htmlbody = $('html, body');
var $body = $('body');

var pageOpen = false;

var ww = $(window).width();
var wh = $(window).height();
var dw = $(document).width();
var dh = $(document).height();

//Slider variables
var slider;
var $slideNumber = $('.js-slide-number');
var $slideTitle = $('.js-slide-title');
var $slideDownload = $('.js-slide-download');
var $cursorPrev = $('.js-cursor--prev');
var $cursorNext = $('.js-cursor--next');
var $cursorPlayPause = $('.js-cursor--playpause');


//Components
var $header = $('.js-header');
var $main = $('.js-main');
var $btnToggleSound = $('.js-toggle-sound');
var btnToggleSound = '.js-toggle-sound';
var $btnClosePages = $('.js-close-pages');
var btnClosePages = '.js-close-pages';
var $btnTogglePage = $('.js-toggle-page');
var btnTogglePage = '.js-toggle-page';
var $btnLinkSlide = $('.js-link-slide');
var btnLinkSlide = '.js-link-slide';
var $btnToggleSlideInfo = $('.js-slide-toggle-info');
var btnToggleSlideInfo = '.js-slide-toggle-info';
var $btnToggleSound = $('.js-slide-toggle-sound');
var btnToggleSound = '.js-slide-toggle-sound';

var $thumbnail = $('.js-thumbnail');
var $collectionList = $('.js-marquee');

/*
var $btnToggleIndex = $('.js-toggle-index');
var btnToggleIndex = '.js-toggle-index';
var $btnToggleOverview = $('.js-toggle-overview');
var btnToggleOverview = '.js-toggle-overview';
*/

// Variables for toggle
var $toggleItem =	$('.js-toggle-item');
var toggleItem	=	'.js-toggle-item';

// Variables for toggle
var $toggleHeader =	$('.js-toggle-header');
var toggleHeader  =	'.js-toggle-header';

// Scroll variables
var didScroll = false;
var currentPos;
var currentItem;
var lastScrollPos = 0;
var offsetHeight = 90;
var headerMenuHeight = 0;
var headerMenuIsFixed = 0;
var scrollDownLastPosition = 0;
var scrollUpLastPosition = 0;
var scrollLastPosition = 0;
var didScroll = false;

// Checks
var isHomepage = false;
var isTouchDevice = false;
var isNavToggled = false;
var isHeaderMenuVisible = false;
var videoIsPlaying = false;
var audioIsPlaying = false;
// var isInformationToggled = false;

main.init = function() {

  document.addEventListener('lazybeforeunveil', function(e){
      var bg = e.target.getAttribute('data-bg');
      if(bg){
          e.target.style.backgroundImage = 'url(' + bg + ')';
      }
  });

  function checkIfTouchDevice(){
      return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
  }

  if(checkIfTouchDevice() === true) {
    $body.addClass('is-touch');
    $body.addClass('is-mobile-device');
    isTouchDevice = true;
    console.log('Touch Device'); //your logic for touch device
  }
  else {
    isTouchDevice = false;
    console.log('Not a Touch Device'); //your logic for non touch device
  }

  objectFitImages();
  main.initPage();
  main.initPjax();
  main.bindEvents();
  main.setLayout();

  // document.addEventListener('touchmove', function (event) {
  //   if (event.scale !== 1) { event.preventDefault(); }
  // }, false);
  //
  // var lastTouchEnd = 0;
  // document.addEventListener('touchend', function (event) {
  //   var now = (new Date()).getTime();
  //   if (now - lastTouchEnd <= 300) {
  //     event.preventDefault();
  //   }
  //   lastTouchEnd = now;
  // }, false);

  $(window).on('load', function() {
    main.setLayout();
  });

  $(window).on('resize', function() {
    main.setLayout();
  });

};

main.initPjax = function() {

  //Functions to run on specific pages
  var Projects = Barba.BaseView.extend({
    namespace: 'projects',
    onEnterCompleted: function() {
      main.setLayout();
      if ($('.js-marquee').length > 0) {
			  main.initMarquee();
			}
    },
    onLeave: function() {
			 main.destroyMarquee();
    }
  });

  var About = Barba.BaseView.extend({
    namespace: 'about'
  });

  var Contact = Barba.BaseView.extend({
    namespace: 'contact'
  });

  var Diary = Barba.BaseView.extend({
    namespace: 'diary'
  });

  var Project = Barba.BaseView.extend({
    namespace: 'project',
    onEnterCompleted: function() {
			if ($('.js-slider').length > 0) {
        main.setLayout();
				main.initSlider('.js-slider');
			}
    }
  });

  Projects.init();
  About.init();
  Contact.init();
  Diary.init();
  Project.init();

  //Start Barba
  Barba.Pjax.start();
  Barba.Prefetch.init();

  var transitionSpeed = 300;

  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },
    fadeOut: function() {
      $(this.oldContainer).toggleClass('fade-out');
      main.unsetBodyColours();
      return new Promise(function(resolve, reject) {
          window.setTimeout(function() {
            resolve();
          }, transitionSpeed);
      });
    },
    fadeIn: function() {
      main.bindEvents();
      var $el = $(this.newContainer);
      $el.toggleClass('fade-in');
      main.setBodyColours($el.data('bgcolour'),$el.data('textcolour'));
      $htmlbody.scrollTop(0);
      this.done();
    }
  });

  Barba.Dispatcher.on('newPageReady', function(newStatus, oldStatus, container, html) {
    main.updateBodyClass($(container).data('namespace'));
    main.updateHeader($(container).data('namespace'), $(container).data('title'));
  });

  Barba.Pjax.getTransition = function() {
    return FadeTransition;
  };

};

main.initPage = function() {

  setTimeout(function() {
      $('.js-main').addClass('is-active');
  },300);

};

main.updateHeader = function(namespace,title) {

  var $navigation = $('.js-navigation');
  var navigation = '.js-navigation';
  var $navigationItem = $('.js-navigation-list-item');
  var navigationItem = '.js-navigation-list-item';
  var $navigationSubtitle = $('.js-navigation-subtitle');
  var navigationSubtitle = '.js-navigation-subtitle';

  $(navigationItem).removeClass('is-active');
  $(navigationItem + '[data-namespace="'+namespace+'"]').addClass('is-active');
  $(navigationSubtitle).html(title);

};

main.updateBodyClass = function(namespace) {

  $body.removeClass();
  $body.addClass('page-is-'+namespace);

  //re-add touch specific classes
  if (isTouchDevice == true) {
    $body.addClass('is-touch');
    $body.addClass('is-mobile-device');
  }

};

main.bindEvents = function() {

  //Redefine all variables used in events
  $toggleItem = $('.js-toggle-item');
  $toggleHeader  = $('.js-toggle-header');
  $btnClosePages = $('.js-close-pages');
  $btnTogglePage = $('.js-toggle-page');
  $btnLinkSlide = $('.js-link-slide');

  $btnToggleSlideInfo = $('.js-slide-toggle-info');
  $btnToggleSlideInfo.on('click', function(e) {
		e.preventDefault();
		main.toggleSlideInfo();
	});

  $btnToggleSound = $('.js-slide-toggle-sound');
  $btnToggleSound.on('click', function(e) {
		e.preventDefault();
		main.toggleSound();
	});

  $('.js-slide-moreinfo').on('click', function(e) {
		main.toggleSlideInfo();
	});

  if(isTouchDevice == false) {
  	$('.js-floatingimage-container').mousemove(function(event) {
  		posX = event.pageX;
  		posY = event.pageY;
  		$('.js-floatingimage').css({
  			'top': posY + 'px',
  			'left': posX + 'px'
  		});
  	});
  }

  // $toggleItem
  $toggleItem.on('click', function(e) {
		e.preventDefault();
    var item = $(this).attr('data-toggle');
    $(this).toggleClass('is-toggled');
		main.toggleItem(item);
	});

  $toggleHeader.on('click', function(e) {
		e.preventDefault();
    $(this).toggleClass('is-toggled');
		main.toggleHeader();
	});

  // $btnClosePages
  $btnClosePages.on('click', function(e) {
    e.preventDefault();
    main.closePages();
  });

  // $btnTogglePage
  $btnTogglePage.on('click', function(e) {
    e.preventDefault();
    main.togglePage($(this).attr('href'));
  });

  // $btnLinkSlide
  $('.c-contact-background').on('click', function(e) {
    e.preventDefault();
  });

  if(isTouchDevice == false) {
    $('.c-contact-background').mousemove(function(event){
      $('.o-cursor--back').css("left", event.pageX);
      $('.o-cursor--back').css("top", event.pageY);
    });
  }

  if(isTouchDevice == false) {
    $('.c-contact-background').hover(
      function() {
         $('.o-cursor--back').css("visibility", 'visible');
      }, function() {
         $('.o-cursor--back').css("visibility", 'hidden');
      }
    );
  }

  $('.c-contact-background').on('click', function(e) {
   history.back();
  });

  if(isTouchDevice == false) {
    $('.js-marquee').mousemove(function(event){
      $('.o-cursor--drag').css("left", event.pageX);
      $('.o-cursor--drag').css("top", event.pageY);
    });
  }

  if(isTouchDevice == false) {
    $('.js-marquee').hover(
      function() {
         $('.o-cursor--drag').css("visibility", 'visible');
      }, function() {
         $('.o-cursor--drag').css("visibility", 'hidden');
      }
    );
  }

  // $btnLinkSlide
  $btnLinkSlide.on('click', function(e) {
    e.preventDefault();
    main.closePages();
    main.findAndGoToSlide($(this).data('project'),$(this).data('slide'));
  });

  if(isTouchDevice == false) {
    $('.js-collection, .js-blogpost').hover(
      function() {
        var bgcolour = $(this).attr('data-bgcolour');
        var textcolour = $(this).attr('data-textcolour');
        setTimeout(function(){
          main.setBodyColours(bgcolour, textcolour);
        },150);
        // $(this).attr('is-active');
        // $(this).siblings('[data-project="'+$(this).data('project')+'"][data-slide="'+$(this).data('slide')+'"]').addClass('is-active');
      }, function() {
        setTimeout(function(){
          main.unsetBodyColours();
        },150);
        // $(this).removeClass('is-active');
        // $(this).siblings('[data-project="'+$(this).data('project')+'"][data-slide="'+$(this).data('slide')+'"]').removeClass('is-active');
      }
    );
  }

};

main.introSlideInfo = function() {

  setTimeout(function() {
    $('.js-slide-toggle-info').toggleClass('is-toggled');
    $('.js-slide-moreinfo').toggleClass('is-active');
  },300);
  setTimeout(function() {
    $('.js-slide-toggle-info').removeClass('is-toggled');
    $('.js-slide-moreinfo').removeClass('is-active');
  },4000);

};



main.toggleSlideInfo = function() {

  $('.js-slide-toggle-info').toggleClass('is-toggled');
  $('.js-slide-moreinfo').toggleClass('is-active');

};

var my_mute = true;

main.setSoundState = function() {

  if(my_mute == false) {
    $btnToggleSound.find('.o-button--toggle__text').html('Sound On');
  } else {
    $btnToggleSound.find('.o-button--toggle__text').html('Sound Off');
  }

};

main.toggleSound = function() {

  my_mute = !my_mute;

  function toggleMute(elem) {
      if(my_mute == false) {
        elem.muted = false;
        main.setSoundState();
      } else {
        main.setSoundState();
        elem.muted = true;
      }
  }

  function toggleMuteIframe(iframe) {
    if(my_mute == false) {
        iframe.contentWindow.postMessage('{"method":"setVolume", "value":1}','*');
        main.setSoundState();
    } else {
        iframe.contentWindow.postMessage('{"method":"setVolume", "value":0}','*');
        main.setSoundState();
    }
  }

  var videos = document.querySelectorAll("video"),
      iframes = document.querySelectorAll("iframe"),
      audios = document.querySelectorAll("audio");

  [].forEach.call(videos, function(video) { toggleMute(video); });
  [].forEach.call(iframes, function(iframe) { toggleMuteIframe(iframe); });
  [].forEach.call(audios, function(audio) { toggleMute(audio); });

};

main.toggleItem = function(item) {

  $(this).toggleClass('is-toggled');
  $('[data-to-toggle="'+item+'"]').toggleClass('u-hidden');

};

main.destroyMarquee = function() {

  setTimeout(function(){
    $collectionList.liMarquee('destroy');
  },220);

};

main.initMarquee = function() {

  // window.lazySizesConfig = window.lazySizesConfig || {};
  // window.lazySizesConfig.init = false;

  var scrollAmount = 4;
  if(ww > 1680) {
    var scrollAmount = ww/240;
  }else if(ww > 1024) {
    var scrollAmount = ww/180;
  }else {
    var scrollAmount = ww/120;
  }

  $collectionList = $('.js-marquee');
  $collectionList.liMarquee({
    circular:false,
    startShow:true,
    scrollDelay:80,
    scrollAmount:scrollAmount
  });

  // function checkPos(marquee) {
  //   console.log(marquee.children('.mMove').offset());
  // }

  // var myInterval;
  //
  // function initInterval(marquee) {
  //   // myInterval = setInterval(checkPos(marquee), 80);
  //   myInterval = setInterval(function(){
  //     console.log("Hello");
  //     var offset = marquee.offset();
  //     if(offset.left > marquee.width() - ww) {
  //       console.log('set back');
  //     }
  //   }, 80);
  // }

  if(isTouchDevice == false) {
    $('.js-marquee').liMarquee('stop');
    $('.js-collection').hover(
      function() {
        $(this).children('.js-marquee').liMarquee('start');
        // initInterval($(this).children('.mMove'));
      }, function() {
        $(this).children('.js-marquee').liMarquee('stop');
        // clearInterval(myInterval);
      }
    );
  }

  // if(isTouchDevice == false) {
  //   $collectionList.liMarquee('stop');
  //   $collectionList.hover(
  //     function() {
  //       $(this).liMarquee('start');
  //       // initInterval($(this).children('.mMove'));
  //     }, function() {
  //       $(this).liMarquee('stop');
  //       // clearInterval(myInterval);
  //     }
  //   );
  // }

};

main.setBodyColours = function(bgcolour,textcolour) {

  $body.css('background-color',bgcolour);
  $body.css('color',textcolour);
  $header.css('color',textcolour);
  $('.u-border').css('border-color',textcolour);
  $('.o-audio-icon__shape').css('fill',textcolour);

  $toggleHeader.find('span').css('background-color',textcolour);

};

main.unsetBodyColours = function() {

  $body.css('background-color','#fcfcfc');
  $body.css('color','#1d1d1d');
  $header.css('color','#1d1d1d');
  $('.u-border').css('border-color','#1d1d1d');
  $('.o-audio-icon__shape').css('fill','#1d1d1d');

  $toggleHeader.find('span').css('background-color','#1d1d1d');

};

main.toggleHeader = function() {

  if(isNavToggled !== true) {
    main.scrollToTop();
  }

  isNavToggled = !isNavToggled;
  $header.toggleClass('is-toggled');

};

main.closePages = function() {

  pageOpen = false;
  $body.removeClass('overlay-page-is-open');

  $('.js-overlay-page.is-active').removeClass('is-active');
  $('.js-main').addClass('is-active');

};

main.togglePage = function(page) {

  pageOpen = true;
  $body.addClass('overlay-page-is-open');

  $('.js-overlay-page.is-active').removeClass('is-active');
  $('.js-main.is-active').removeClass('is-active');
  $('.js-current-page').html($('nav ul li a[rel="'+page+'"]').text());

  setTimeout(function(){
    $('.js-overlay-page[data-page="'+page+'"]').toggleClass('is-active');
    $('nav ul li a[rel="'+page+'"]').parent().toggleClass('is-active');
  },440);

};

main.findAndGoToSlide = function(project,slideNumber) {

  var slideIndex;
  $.each(slider.slides, function(index, value) {
      if(value.content.attr('data-project') == project && value.content.attr('data-slide') == slideNumber) {
        slideIndex = index;
        return false;
      }
  });

  slider.goTo(slideIndex);

};

// main.destroySlider = function() {
//
//   slider.destroy();
//
// };

main.initSlider = function() {

  //Slider variables
  $slideNumber = $('.js-slide-number');
  $slideTitle = $('.js-slide-title');
  $slideDownload = $('.js-slide-download');
  $btnToggleSound = $('.js-slide-toggle-sound');
  $cursorPrev = $('.js-cursor--prev');
  $cursorNext = $('.js-cursor--next');
  $cursorPlayPause = $('.js-cursor--playpause');

  setTimeout(function() {
      $('.js-main').addClass('is-active');
  },300);

  setTimeout(function() {
      $('.js-slide-content').addClass('is-active');
  },600);


  if( $('.barba-container').attr('data-showinfo') == 'true' ) {
    main.introSlideInfo();
  }

  $('.js-slider').css('overflow', 'visible');

  if(isTouchDevice == false) {
    $('.js-slider').royalSlider({
      loop: false,
      keyboardNavEnabled: true,
      autoScaleSlider: false,
      autoHeight: false,
      imageScalePadding: 0,
      imageScaleMode: 'none',
      imageAlignCenter: false,
      slidesSpacing: 0,
      globalCaption: false,
      navigateByClick: false,
      arrowsAutoHide: true,
      arrows: false,
      sliderDrag: false,
      sliderTouch: false,
      transitionSpeed: 0,
      numImagesToPreload: 2,
      fadeinLoadedSlide: true
    });
  } else {
    $('.js-slider').royalSlider({
      loop: false,
      keyboardNavEnabled: true,
      autoScaleSlider: false,
      autoHeight: false,
      imageScalePadding: 0,
      imageScaleMode: 'none',
      imageAlignCenter: false,
      slidesSpacing: 0,
      globalCaption: false,
      navigateByClick: false,
      arrowsAutoHide: false,
      arrows: false,
      sliderDrag: true,
      sliderTouch: true,
      transitionSpeed: 0,
      numImagesToPreload: 2,
      fadeinLoadedSlide: true
    });
  }

  $.fn.lazysizesRoyal = function(){
      var addPrevNextPreload = function(){
          var setPreload;
          var slider = $(this).data('royalSlider');

          if(!slider){return;}
          setPreload = function(){
              slider.currSlide.holder
                  .next()
                  .add(slider.currSlide.holder.prev())
                  .find('.lazyload')
                  .addClass('lazypreload')
              ;
          };
          setPreload();
          slider.ev.on('rsAfterSlideChange', setPreload);
      };
      return this.each(addPrevNextPreload);
  };

  $('.js-slider').lazysizesRoyal();

  slider = $('.js-slider').data('royalSlider');

  if(isTouchDevice == false) {
    $('.js-slider').on('mousemove', function(e){
      if (slider.currSlide.content.children('.o-slide--video').length > 0 || slider.currSlide.content.children('.o-slide--audio').length > 0) {
        if (e.pageX < ww/5){
      		$('.js-slider').removeClass('hover-next');
      		$('.js-slider').addClass('hover-prev');
          $cursorPlayPause.css("visibility", 'hidden');
          $cursorPrev.css("visibility", 'visible');
          $cursorNext.css("visibility", 'hidden');
          $cursorPrev.css("left", e.pageX);
          $cursorPrev.css("top", e.pageY);
      	} else if (e.pageX >= ww - ww/5 ) {
      		$('.js-slider').removeClass('hover-prev');
      		$('.js-slider').addClass('hover-next');
          $cursorPlayPause.css("visibility", 'hidden');
          $cursorPrev.css("visibility", 'hidden');
          $cursorNext.css("visibility", 'visible');
          $cursorNext.css("left", e.pageX);
          $cursorNext.css("top", e.pageY);
      	} else {
          $cursorNext.css("visibility", 'hidden');
          $cursorPrev.css("visibility", 'hidden');
          $cursorPlayPause.css("visibility", 'visible');
          $cursorPlayPause.css("left", e.pageX);
          $cursorPlayPause.css("top", e.pageY);
        }
      } else {
        if (e.pageX < ww/2){
      		$('.js-slider').removeClass('hover-next');
      		$('.js-slider').addClass('hover-prev');
          $cursorPrev.css("visibility", 'visible');
          $cursorNext.css("visibility", 'hidden');
          $cursorPrev.css("left", e.pageX);
          $cursorPrev.css("top", e.pageY);
      	} else if (e.pageX >= ww/2) {
      		$('.js-slider').removeClass('hover-prev');
      		$('.js-slider').addClass('hover-next');
          $cursorPrev.css("visibility", 'hidden');
          $cursorNext.css("visibility", 'visible');
          $cursorNext.css("left", e.pageX);
          $cursorNext.css("top", e.pageY);
      	}
      }
    });
  } else {
    $cursorNext.css("visibility", 'hidden');
    $cursorPrev.css("visibility", 'hidden');
    $cursorPlayPause.css("visibility", 'hidden');
  }

  $('.js-slide-hud, .js-slide-moreinfo').on('mouseover', function(e){
    $cursorNext.css("visibility", 'hidden');
    $cursorPrev.css("visibility", 'hidden');
    $cursorPlayPause.css("visibility", 'hidden');
  });


  if (isTouchDevice == false) {
    slider.ev.on('rsSlideClick', function(event, originalEvent) {
      if (slider.currSlide.content.children('.o-slide--video').length > 0) {
        if (originalEvent.pageX < ww/5){
      		slider.prev();
    	  } else if (originalEvent.pageX >= ww - ww/5 ) {
          slider.next();
      	} else {
          main.playPauseVideo(slider.currSlide.content);
        }
      } else if (slider.currSlide.content.children('.o-slide--audio').length > 0) {
        if (originalEvent.pageX < ww/5){
      		slider.prev();
    	  } else if (originalEvent.pageX >= ww - ww/5 ) {
          slider.next();
      	} else {
          main.playPauseAudio(slider.currSlide.content);
        }
      } else {
        if (originalEvent.pageX < ww/2){
      		slider.prev();
      	} else if (originalEvent.pageX >= ww/2) {
          slider.next();
      	}
      }
    });
  } else {
    $('.js-slider').on('touchstart mousedown', function(e) {
      if (slider.currSlide.content.children('.o-slide--video').length > 0) {
          main.playPauseVideo(slider.currSlide.content);
          $btnToggleSound.removeClass('u-hidden');
      } else if (slider.currSlide.content.children('.o-slide--audio').length > 0) {
          main.playPauseAudio(slider.currSlide.content);
      }
  	});
  }

  // slider.ev.on('click', function(event, originalEvent) {
  //   if (slider.currSlide.content.children('.o-slide--video').length > 0) {
  //       alert('clicked, play video');
  //       main.playPauseVideo(slider.currSlide.content);
  //   } else if (slider.currSlide.content.children('.o-slide--audio').length > 0) {
  //       alert('clicked, play audio');
  //       main.playPauseAudio(slider.currSlide.content);
  //   }
  // });

  $('.js-arrow--prev').on('click', function(e) {
    slider.prev();
	});

  $('.js-arrow--next').on('click', function(e) {
    slider.next();
	});

  slider.ev.on('rsBeforeMove', function(event, type, userAction ) {
      // before any transition start (including after drag release)
      if(type == 'prev' && slider.currSlideId == 0 ) {
          var prevProject = slider.currSlide.content.attr('data-prev');
          Barba.Pjax.goTo(prevProject);
      }
      if(type == 'next' && slider.currSlideId + 1 == slider.numSlides) {
          var nextProject = slider.currSlide.content.attr('data-next');
          Barba.Pjax.goTo(nextProject);
      }
  });

  // $('.js-slider').on('click', function(e){
  // 	if (e.pageX < $(window).width()/2){
  // 		slider.prev();
  // 	} else if (e.pageX > $(window).width()/2) {
  // 		slider.next();
  // 	}
  //   console.log(slider.currSlideId, type, slider.numSlides);
  // });

  function updColours() {
    var bgcolour = slider.currSlide.content.attr('data-bgcolour');
    var textcolour = slider.currSlide.content.attr('data-textcolour');
    setTimeout(function(){
      main.setBodyColours(bgcolour, textcolour);
    },150);
    main.setBodyColours(bgcolour, textcolour);
  }

  function updCount() {
     $slideNumber.html( (slider.currSlideId+1) + ' of ' + slider.numSlides );
  }

  function updTitle() {
     $slideTitle.html(slider.currSlide.content.attr('data-title'));
  }

  function updDownload() {
    if( slider.currSlide.content.attr('data-download') == 'true' ) {
        $slideDownload.html('<a target="_blank" href="' + slider.currSlide.content.attr('data-downloadurl') + '">&#8595; Download</a>' );
    } else {
        $slideDownload.html('' );
    }
  }

  function checkSlideType() {
    if (isTouchDevice == false) {
      if (slider.currSlide.content.children('.o-slide--video').length > 0) {
        main.initVideo();
        $btnToggleSound.removeClass('u-hidden');
      } else {
        $btnToggleSound.addClass('u-hidden');
      }
    } else {
      if (slider.currSlide.content.children('.o-slide--video').length > 0) {
          main.initVideo();
          main.playPauseVideo(slider.currSlide.content);
          $btnToggleSound.removeClass('u-hidden');
      } else if (slider.currSlide.content.children('.o-slide--audio').length > 0) {
          main.playPauseAudio(slider.currSlide.content);
          $btnToggleSound.addClass('u-hidden');
      } else {
          $btnToggleSound.addClass('u-hidden');
      }
    }
      // if (slider.currSlide.content.children('.o-slide--video').length > 0) {
      //     main.playPauseVideo(slider.currSlide.content);
      // } else if (slider.currSlide.content.children('.o-slide--audio').length > 0) {
      //     main.playPauseAudio(slider.currSlide.content);
      // }
    // if (slider.currSlide.content.children('.o-slide--audio').length > 0) {
    //   main.initAudio();
    // }
  }

  slider.ev.on('rsAfterSlideChange', function() {
    updColours();
    updCount();
    updTitle();
    updDownload();
    checkSlideType();
    if(videoIsPlaying == true) {
      //Pause all playing videos after slide change;
      main.pauseAllVideos();
    }
    if(audioIsPlaying == true) {
      //Pause all playing videos after slide change;
      main.pauseAllAudio();
    }
    images = slider.slides;
  });

  //Run functions on initialised slider to update metadata and content
  updColours();
  updCount();
  updTitle();
  updDownload();
  checkSlideType();

};

main.pauseAllAudio = function() {

  $('.js-audio').each(function() {
    var audioId = $(this).get(0);
    audioId.pause();
    audioIsPlaying = false;
    main.updatePlayCursor('pause');
  });

};

main.playPauseAudio = function(currAudio) {

  var audioId = currAudio.find('.js-audio').get(0);

  if (audioId.paused) {
      audioId.play();
      audioIsPlaying = true;
      main.updatePlayCursor('play');
  } else {
      audioId.pause();
      audioIsPlaying = false;
      main.updatePlayCursor('pause');
  }

};

main.pauseAllVideos = function() {

  console.log('Pause all videos');

  $('.js-video').find('iframe.playing').each(function() {
    var id = $(this).attr('id');
    var iframe = document.getElementById(id);
    main.vimeoPost('pause',null,iframe);
  });

};

main.playPauseVideo = function(currVideo) {

  var id = currVideo.find('iframe').attr('id');
  var iframe = document.getElementById(id);

  if(videoIsPlaying) {
      //If the video is playing, pause it.
      main.vimeoPost('pause',null,iframe);
  } else {
    //If the video is not playing, play it.
      main.vimeoPost('play',null,iframe);
  }

}

main.vimeoPost = function(action, value, target) {

  var data = { method: action };
  if (value) {
    data.value = value;
  }
  target.contentWindow.postMessage(JSON.stringify(data), '*');

};

main.updatePlayCursor = function(status) {

  if(status == 'play') {
    //If a video or audio is playing, show 'Pause' message on  the cursor.
    $cursorPlayPause.addClass('has-pause-message');
  } else {
    //If a video or audio is playing, show 'Play' message on  the cursor.
    $cursorPlayPause.removeClass('has-pause-message');
  }

};

main.initVideo = function() {

  $(window).on('message onmessage', function(e) {

      /* Get message and sender from the event */
      var message = JSON.parse(e.originalEvent.data);
      var sender = document.getElementById(message.player_id);
      console.log(message);
      /* If the message.event is "ready", then send a message to add eventlisteners for play and pause also */
      if(message.event == "ready") {
     	 main.vimeoPost('addEventListener','play',sender);
       main.vimeoPost('addEventListener','pause',sender);
      }

      /* Because of the above, we will start receiving events when video is played or paused - we will toggle a class, just to show it visually */
      if(message.method == 'play') {
        console.log('Playing the video');
        sender.classList.add('playing');
    		sender.parentNode.classList.add('is-playing');
        videoIsPlaying = true;
        main.updatePlayCursor('play');
      }
      if(message.method == 'pause') {
        console.log('Pausing the video');
        sender.classList.remove('playing');
        sender.parentNode.classList.remove('is-playing');
        videoIsPlaying = false;
        main.updatePlayCursor('pause');
      }

  });

};

main.setLayout = function() {

  ww = $.windowWidth();
  wh = $.windowHeight();
  dw = $(document).width();
  dh = $(document).height();

  $main.css('min-height',wh);

};


main.scrollTo = function(href) {

  $('html:not(:animated), body:not(:animated)').animate({
    scrollTop: $(href).position().top - $header.outerHeight()
  }, 660, 'easeInOutQuart');

  $htmlbody.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function() {
    $htmlbody.stop();
  });

};

main.scrollToTop = function() {

  $htmlbody.animate({
    scrollTop: 0
  }, 320);

};

main.showHeaderMenu = function() {

  isHeaderMenuVisible = true;
  $header.removeClass('has-scrolled-state');

};

main.hideHeaderMenu = function() {

  $header.addClass('has-scrolled-state');
  isHeaderMenuVisible = false;

};

main.checkOnScroll = function(scrollPosition) {


  if (scrollPosition > offsetHeight) {
    main.hideHeaderMenu();
  } else {
    if (!isHeaderMenuVisible) {
      main.showHeaderMenu();
    }
  }

  // //Header (menu) behaviour:
  // if (scrollPosition > scrollLastPosition) { // When scrolling down
  //
  //   scrollDownLastPosition = scrollPosition;
  //
  //   if (scrollPosition > (offsetHeight)) {
  //     if (scrollPosition >= (offsetHeight + scrollUpLastPosition + 15)) {
  //       setTimeout(function() {
  //         main.hideHeaderMenu();
  //       }, 50);
  //     }
  //   }
  //
  // } else if (scrollPosition < scrollLastPosition) { // When scrolling up
  //
  //   scrollUpLastPosition = scrollPosition;
  //
  //   if (!isHeaderMenuVisible) {
  //     if (scrollPosition <= (offsetHeight + scrollDownLastPosition - 100)) {
  //       main.showHeaderMenu();
  //     }
  //   }
  //
  // }

  scrollLastPosition = scrollPosition;

};

//On Scroll
$(document).on('scroll', function(event) {

  didScroll = true;
  main.checkOnScroll($(this).scrollTop());

});

setInterval(function() {

  if (didScroll) {
    didScroll = false;
  }

}, 200);


$(main.init);
